import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

import Amplify from "aws-amplify";
import AmplifyVue from '@aws-amplify/ui-vue';
import awsExports from "./aws-exports";

Amplify.configure(awsExports);

const app = createApp(App);
app.use(router);
app.use(AmplifyVue);
router.isReady().then(() => {
  app.mount('#app')
})
