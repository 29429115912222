/*
 * Copyright 2022 Ducksify SA. or its affiliates. All Rights Reserved.
 *
 * This requires Tailwind CSS v2.0+
 *
 */

<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="this.my_message !== ''" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon v-if="my_type === 'info'" class="h-6 w-6 text-green-400" aria-hidden="true" />
                <TrashIcon v-if="my_type === 'delete'" class="h-6 w-6 text-ducksify-500" aria-hidden="true" />
                <ExclamationCircleIcon v-if="my_type === 'error'" class="h-6 w-6 text-red-400" aria-hidden="true" />
                <ExclamationTriangleIcon v-if="my_type === 'warning'" class="h-6 w-6 text-yellow-400" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">{{ this.my_title }}</p>
                <p class="mt-1 text-sm text-gray-500">{{ this.my_message }}.</p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="this.my_message = ''" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  CheckCircleIcon,
  XMarkIcon,
  TrashIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/vue/24/outline'

export default {
  name: 'PopperNotification',
  async created() {},
  data() {
    return {
      my_title: '',
      my_message: '',
    }
  },
  methods: {
    setAlert(status) {
      this.my_message = status.message;
      this.my_title = status.title;
      this.my_type = status.type ? status.type : 'info';
      //console.log('[Notification] watch.message: this.my_title=%s, this.my_message=%s', this.my_message, this.my_title);
    },
    hideAlert() {
      // Close after 5 seconds, implicit, if my_message is empty it closes
      this.my_title = '';
      this.my_message = '';
    }
  },
  components: {
    CheckCircleIcon,
    XMarkIcon,
    TrashIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
  },
  setup() {},
  props: ['status'],
  computed: {},
  watch: {
    status(newValue) {
      if (newValue) {
        this.setAlert(newValue);
        // Close after 5 seconds
        setTimeout(this.hideAlert, 5000);
      }
    },
  },
}
</script>
