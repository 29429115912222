import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    //component: () => import(/* webpackChunkName: "about" */ '../components/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/configs/:accountId/licenses/:licenseId/tenants/:tenantId',
    name: 'licenseTenant',
    component: () => import(/* webpackChunkName: "about" */ '../views/TenantView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/accounts/:accountId/licenses/:licenseId/tenants/:tenantId',
    name: 'licenseTenant',
    component: () => import(/* webpackChunkName: "about" */ '../views/TenantView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/accounts/:accountId/licenses/:licenseId/tenants',
    name: 'licenseTenants',
    component: () => import(/* webpackChunkName: "about" */ '../views/TenantsView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/accounts/:accountId/licenses/:licenseId',
    name: 'accountLicense',
    component: () => import(/* webpackChunkName: "about" */ '../views/LicenseView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/accounts/:accountId/licenses',
    name: 'accountLicenses',
    component: () => import(/* webpackChunkName: "about" */ '../views/LicensesView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/accounts/:accountId',
    name: 'account',
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountsView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/licenses/:licenseId',
    name: 'license',
    component: () => import(/* webpackChunkName: "about" */ '../views/LicenseView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/licenses',
    name: 'licenses',
    component: () => import(/* webpackChunkName: "about" */ '../views/LicensesView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/tenants',
    name: 'tenants',
    component: () => import(/* webpackChunkName: "about" */ '../views/TenantsView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/tenants/:tenantId',
    name: 'tenant',
    component: () => import(/* webpackChunkName: "about" */ '../views/TenantView.vue'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requiresAuth: true },
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//router.beforeEach((to, from, next) => {
//  // instead of having to check every route record with
//  // to.matched.some(record => record.meta.requiresAuth)
//  if (to.meta.requiresAuth && !auth.isLoggedIn()) {
//    // this route requires auth, check if logged in
//    // if not, redirect to login page.
//    return {
//      path: '/login',
//      // save the location we were at to come back later
//      query: { redirect: to.fullPath },
//    }
//  }
//})

export default router
