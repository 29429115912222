/*
 * Copyright 2022 Ducksify SA. or its affiliates. All Rights Reserved.
 *
 * This requires Tailwind CSS v2.0+
 *
 */

<template>
  <!-- Replace with your content -->
  <div class="rounded-sm bg-white px-5 py-6 shadow sm:px-6">
    <div class="h-96 rounded-sm">
      <div id="app">
        <h1 class="text-2xl">Dakcess Dashboard</h1>


      <!-- /End app -->
      </div>
    </div>
  </div>
  <!-- /End replace -->
</template>

<script>

export default {
  name: 'HomeView',
  async created() {},
  data() {
    return {}
  },
  methods: {},
  components: {},
  setup() {},
  props: [],
  computed: {}
}
</script>
